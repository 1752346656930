import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Tbl from "../pages/tbl"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid className="background-noresi">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="size-image-logo1">
                  <LazyLoadImage
                    alt={"Logo HLG"}
                    src={
                      "https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/result/result/logo-120x130.png"
                    }
                    className={"img img-fluid"}
                    effect="blur"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <h1 className="text-center text">
                  Lihat Nomor Resi Kamu Disini.
                </h1>
                <p className="text-center text">
                  Pengiriman dilakukan bertahap, jika belum menemukan nama &
                  nomor resi milikmu silakan hubungi CS kami via WA
                  <a
                    href="https://wa.link/6fy4s8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    0812-1321-7318
                  </a>
                </p>
                <p className="text-center text">
                  Ohya, bantu kami mengetahui paket sudah kamu terima dengan
                  selamat dengan upload foto di feed/story instagram dan tag
                  kami di akun @happyloveguide & @lovecoach.id ya
                </p>{" "}
                <br />
                <p className="text">
                  Tanggal kirim: 8 Maret - 15 Maret 2023 via ekspedisi JNE.{" "}
                  <br />
                  Cara cek status pengiriman:
                  <br />
                  Copy & Paste nomor resi kamu melalui website{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.jne.co.id/id/beranda"
                  >
                    https://www.jne.co.id/id/beranda
                  </a>
                </p>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={12}>
                <Tbl data={this.dataSet}></Tbl>
              </Col>
            </Row>
          </Container>
        </Container>
      </React.Fragment>
    )
  }

  dataSet = [
    ["100353452", "2510272300019456", "Adina Adiati Sylvia Dewi"],
    ["100352912", "2510272300019233", "Aisyah Maesaroh"],
    ["100351176", "2510272300017989", "Allyta Rizkiah"],
    ["100352541", "2510272300018995", "Almira Gitta Hapsari"],
    ["100351308", "2510272300018631", "Ana"],
    ["100351219", "2510272300018037", "Anastasia Prameshwarie"],
    ["100353182", "2510272300019340", "Anestia Hardianti"],
    ["100352309", "2510272300019209", "Angelina"],
    ["100353405", "2510272300019365", "Anik Wahyu"],
    ["100353336", "2510272300019373", "Anisa Setiarini"],
    ["100352218", "2510272300018664", "Anne"],
    ["100352229", "2510272300018656", "Annisa Nindi"],
    ["100351764", "2510272300018482", "Antika Hapsari"],
    ["100353191", "2510272300019332", "Arin Carolin"],
    ["100352743", "2510272300019092", "Arini Agustina"],
    ["100352430", "2510272300018961", "Astutik"],
    ["100352348", "2510272300019001", "Azifah Devy"],
    ["100352568", "2510272300019134", "Basalina"],
    ["100351964", "2510272300018532", "Caroline"],
    ["100351714", "2510272300018359", "Cathryn Alice"],
    ["100352905", "2510272300019142", "Cecil"],
    ["100351588", "2510272300018201", "Chandra Dewi Rahmadani"],
    ["100351980", "2510272300019316", "Chessa Stefani Rovin"],
    ["100352192", "2510272300018854", "Dee"],
    ["100351620", "2510272300018581", "Deni Ekawati"],
    ["100351694", "2510272300018573", "Desiana Joe"],
    ["100352065", "2510272300019027", "Devi Sukaesih"],
    ["100352138", "2510272300018607", "Devina Puspitasari"],
    ["100351773", "2510272300018474", "Dewi Sartika"],
    ["100352696", "2510272300019175", "Dewi Yuliyani"],
    ["100351644", "2510272300018284", "Dhika Fentiananda"],
    ["100353022", "2510272300019274", "Dina Paulina"],
    ["100351608", "2510272300018300", "Dini Purnama Dewi"],
    ["100352951", "2510272300019282", "Dita Pratiwi Kusuma Wardani"],
    ["100352187", "2510272300018748", "Diyani Cholifa"],
    ["100352443", "2510272300018888", "Dothy Saragih"],
    ["100352639", "2510272300018987", "Dwi Novika"],
    ["100351632", "2510272300018292", "Eka Weli Ningsih"],
    ["100352152", "2510272300018706", "Eliya Triamie"],
    ["100351146", "2510272300018813", "Eliza Beth"],
    ["100351582", "2510272300018219", "Ella"],
    ["100351446", "2510272300018243", "Endah Sasmita"],
    ["100351180", "2510272300019035", "Ernawati"],
    ["100352160", "2510272300018755", "Ervina Tambunan"],
    ["100352472", "2510272300019324", "Fahmi Muharomi Khoirunnisa"],
    ["100351595", "2510272300018326", "Fanni"],
    ["100351561", "2510272300018235", "Fathia Yustikadewi"],
    ["100346096", "2510272300017955", "Feby"],
    ["100352146", "2510272300018714", "Fitrania Imelda"],
    ["100352998", "2510272300019308", "Fitri Nur (Ips)"],
    ["100352570", "2510272300018912", "Fitri Nurul Syahidah"],
    ["100352202", "2510272300018896", "Fransiska Cahya Marina"],
    ["100352297", "2510272300018839", "Frischa"],
    ["100351347", "2510272300018144", "Hanna Hotmauly"],
    ["100351689", "2510272300018276", "Ibu Hutapea"],
    ["100352606", "2510272300019290", "Iik Hikmawanti"],
    ["100352069", "2510272300018722", "Indy"],
    ["100351611", "2510272300018367", "Iren Konsolata"],
    ["100351984", "2510272300018615", "Irma Fahriani"],
    ["100351596", "2510272300018318", "Islah Hanifa"],
    ["100352270", "2510272300019266", "Jessica"],
    ["100351351", "2510272300018029", "Kiel"],
    ["100352063", "2510272300018904", "Kiki Fetriani"],
    ["100352197", "2510272300018672", "Kiki Rizki Amalia"],
    ["100351440", "2510272300018433", "Kristina"],
    ["100351471", "2510272300018169", "Kun Kanthi Ip"],
    ["100352041", "2510272300018789", "Lasiah"],
    ["100352089", "2510272300019431", "Lenny"],
    ["100352452", "2510272300018870", "Leny Rahayu Lestari"],
    ["100351566", "2510272300018227", "Linggawati Tanujaya"],
    ["100351256", "2510272300018011", "Lulu Vania"],
    ["100351837", "2510272300018557", "Maria Lulu"],
    ["100352636", "2510272300019381", "Maria Stephanie"],
    ["100352388", "2510272300019191", "Masyitoh Putri Rangkuti"],
    ["100352600", "2510272300019357", "Mei Octa"],
    ["100352031", "2510272300018730", "Meidayana Kusuma"],
    ["100352791", "2510272300019159", "Mela Milani"],
    ["100351543", "2510272300018508", "Melissa Mahpud"],
    ["100353497", "2510272300019449", "Merdiani Rahmania"],
    ["100352548", "2510272300018938", "Michaela Glady"],
    ["100351652", "2510272300018425", "Monica Inawati"],
    ["100351380", "2510272300018375", "Mutia Sahara"],
    ["100353122", "2510272300019423", "Mutiara Dwi Bahtera Iriani Wakiran"],
    ["100352665", "2510272300019118", "Nafik"],
    ["100351541", "2510272300018193", "Nancy"],
    ["100352438", "2510272300018953", "Nanda Safitri"],
    ["100351990", "2510272300018797", "Neny"],
    ["100352476", "2510272300018946", "Nia Dewi"],
    ["100352689", "2510272300019050", "Novi Astuti"],
    ["100351202", "2510272300017963", "Novita"],
    ["100351715", "2510272300018391", "Novita Tania"],
    ["100351866", "2510272300018540", "Nuna"],
    ["100351279", "2510272300018094", "Nur Vita Yulianti"],
    ["100351303", "2510272300018102", "Nuriska"],
    ["100352523", "2510272300019399", "Nurul Hasanah"],
    ["100352956", "2510272300019225", "Nurul Islamiyah"],
    ["100352638", "2510272300019126", "Poppy"],
    ["100351252", "2510272300018003", "Priscilla Valentina"],
    ["100351210", "2510272300017971", "Putri"],
    ["100351474", "2510272300018177", "Putri Daryanti"],
    ["100351381", "2510272300018250", "Putu Cinthia Delis"],
    ["100351795", "2510272300018466", "Rahmah El Fauziah"],
    ["100351900", "2510272300018458", "Ratna Karima"],
    ["100352265", "2510272300018979", "Ria Wati"],
    ["100351346", "2510272300018136", "Riarasg"],
    ["100351294", "2510272300018268", "Ricka Ayu Sugiarti"],
    ["100352166", "2510272300018698", "Rieke Ardillah"],
    ["100351711", "2510272300018409", "Rika K"],
    ["100352258", "2510272300018847", "Rina"],
    ["100352242", "2510272300018649", "Rina Siti Hodijah"],
    ["100351650", "2510272300018490", "Risalatul Ma'Unah"],
    ["100352174", "2510272300019217", "Risca"],
    ["100351306", "2510272300018110", "Rizka Amalia"],
    ["100352547", "2510272300019076", "Rizkiyah"],
    ["100351770", "2510272300018565", "Roma"],
    ["100352182", "2510272300018680", "Rosa Rina"],
    ["100351765", "2510272300018383", "Rosiana Pertiwi"],
    ["100352074", "2510272300018763", "Rubiyah"],
    ["100352737", "2510272300019100", "Sari Shaviriyani"],
    ["100351253", "2510272300018045", "Sekar Arum Nurtriani"],
    ["100352726", "2510272300019043", "Selma"],
    ["100352325", "2510272300019407", "Sepna Milia"],
    ["100352553", "2510272300018920", "Setyo Budi Hartini"],
    ["100352179", "2510272300018599", "Shafira Rizqi Aditya Poetri"],
    ["100351421", "2510272300018334", "Sheila Sausan Hanifah M"],
    ["100352045", "2510272300018771", "Siti Khadijah"],
    ["100352140", "2510272300018862", "Susi"],
    ["100351245", "2510272300017997", "Susy Irawati"],
    ["100351926", "2510272300018805", "Tessa Adytia Sasmita"],
    ["100351720", "2510272300018342", "Uci"],
    ["100351234", "2510272300018516", "Utami"],
    ["100351680", "2510272300018417", "Vindy Violita R"],
    ["100352785", "2510272300019167", "Wafa Fahirah"],
    ["100352301", "2510272300018821", "Wahyuningsih"],
    ["100352804", "2510272300019084", "Wanda"],
    ["100353433", "2510272300019415", "Wanda Astari"],
    ["100351972", "2510272300018623", "Wanda Nur Melia Rahma"],
    ["100351991", "2510272300018524", "Wari"],
    ["100351499", "2510272300018185", "Wenny"],
    ["100352178", "2510272300019019", "Widya Arya Wiranti"],
    ["100352510", "2510272300019183", "Wieke Febyanti Jasirsa"],
    ["100351416", "2510272300018151", "Yekti"],
    ["100351316", "2510272300018128", "Yuanita Nur W"],
    ["100351241", "2510272300018441", "Yulianti"],
    ["100352666", "2510272300019241", "Zuhartina Zainuddin"],
    ["100351298", "P2303080178850", "Isna Buntanus"],
    ["100351277", "2510272300018086", "Yenny Afiyah Novianti"],
    ["100351280", "2510272300018052", "Adia Dessy"],
    ["100351349", "2510272300018060", "Retno Kusumowardani"],
    ["100351373", "2510272300019704", "Hasnah Lidiawati"],
    ["100351385", "2510272300018078", "Elsa Vidya"],
    ["100351501", "2510272300020033", "Prilia Chandradewi"],
    ["100351782", "2510272300019696", "Ai Suarni"],
    ["100351950", "2510272300019795", "Dwi Anindya Putri"],
    ["100351966", "2510272300020181", "Nava Aulika"],
    ["100352236", "2510272300019845", "Ineindrasari"],
    ["100352382", "2510272300019670", "Chaterine"],
    ["100352870", "2510272300020215", "Rian Mei Lestari"],
    ["100353315", "2510272300019662", "Fajrin"],
    ["100353496", "2510272300019837", "Puspa"],
    ["100353503", "2510272300019654", "Sri Oktaningsih"],
    ["100353529", "2510272300019647", "Mutia"],
    ["100353541", "2510272300020371", "Siti Mukhoridah"],
    ["100353545", "2510272300019639", "Rizki Nurul Fitriani"],
    ["100353586", "2510272300019621", "Nita Oktaviani"],
    ["100353753", "2510272300019829", "Efi"],
    ["100353813", "2510272300019860", "Ika Kurnia Anggi Utami"],
    ["100353852", "2510272300019936", "Jeli Susanti"],
    ["100353860", "2510272300019688", "Mutiara Nurul Faadhilah"],
    ["100353866", "2510272300019787", "Wirda Nadia Safira"],
    ["100353878", "2510272300019738", "Valeria"],
    ["100353905", "2510272300019803", "Aulia Hanni"],
    ["100353911", "2510272300019720", "Nurhayati"],
    ["100353913", "2510272300019894", "Wiendy Noviyanti"],
    ["100354003", "2510272300019746", "Lutfi"],
    ["100354014", "2510272300019878", "Vita Merry Marantika"],
    ["100354101", "2510272300019779", "Beatrice"],
    ["100354142", "2510272300019761", "Khadijah"],
    ["100354149", "2510272300019753", "Danny Kurniawan"],
    ["100354171", "2510272300019811", "Lilis Simatupang"],
    ["100354361", "2510272300019852", "Vani"],
    ["100354635", "2510272300019886", "Linawati"],
    ["100354754", "2510272300019910", "Diah Citra"],
    ["100354763", "2510272300019985", "Haifa Murisa"],
    ["100354792", "2510272300019928", "Nyawiji Ayu Purwaningsih"],
    ["100354883", "2510272300019902", "Arintania"],
    ["100355506", "2510272300019944", "Akhmad Haris Maulana"],
    ["100355667", "2510272300019969", "Intoifah Rohamatul Izah"],
    ["100355724", "2510272300019951", "Nur Maharani"],
    ["100355816", "2510272300019993", "Nana"],
    ["100355822", "2510272300019977", "Qadriany Bayyinah"],
    ["100356355", "2510272300020017", "Karina"],
    ["100356903", "2510272300020009", "Nida"],
    ["100356962", "2510272300020025", "Mei Rinta"],
    ["100357836", "2510272300020041", "Alifiana Nur Susanti"],
    ["100357840", "2510272300020058", "Yulia Nur Ulfa"],
    ["100358325", "2510272300020066", "Milea"],
    ["100358460", "2510272300020074", "Yohanna"],
    ["100358736", "2510272300020264", "Miss Mega"],
    ["100359994", "2510272300020082", "Sartiya Monalisa Siregar"],
    ["100363686", "2510272300020090", "Fatimah"],
    ["100372170", "2510272300020249", "Wilda Layli Masruroh"],
    ["100373350", "2510272300020108", "Fanny Jenifer"],
    ["100373813", "2510272300020116", "Elvina Lim"],
    ["100374494", "2510272300020124", "Leli Juwita"],
    ["100380984", "2510272300020132", "Amelia Budi Rahayu"],
    ["100382346", "2510272300020140", "Tri Purwaningsih"],
    ["100383918", "2510272300020157", "Holy"],
    ["100384163", "2510272300020165", "Maisa Ulfah"],
    ["100385580", "2510272300020173", "Rina N"],
    ["100388401", "2510272300020199", "Octavilia"],
    ["100392048", "2510272300020587", "Reski Abdina Sitorus"],
    ["100398823", "2510272300020207", "Fini Ismayanti"],
    ["100403651", "2510272300020223", "Aquita Valentina"],
    ["100405351", "2510272300020231", "Erdisa N"],
    ["100408524", "2510272300020256", "Dini Ayu A"],
    ["100408900", "2510272300020355", "Rieska Ananda"],
    ["100417721", "2510272300020272", "Novita Sari Simamora"],
    ["100417796", "2510272300020280", "Yoga Nur Setiyawati"],
    [
      "100417940",
      "2510272300020322",
      "Dewi Ferawati Nainggolan A.M.K.S.Kep.Ners",
    ],
    ["100418234", "2510272300020298", "Dian Aryani"],
    ["100420147", "2510272300020306", "Desi Faulina"],
    ["100421061", "2510272300020314", "Sintia"],
    ["100423033", "2510272300020330", "Riani"],
    ["100423072", "2510272300020348", "Latifah Adawiyah"],
    ["100428055", "2510272300020363", "Inayah"],
    ["100438340", "2510272300020389", "Ephy"],
    ["100440827", "2510272300020397", "Aulie Tsalistia"],
    ["100441391", "2510272300020405", "Farida Liana"],
    ["100443692", "2510272300020413", "Risa Yuviana Wahyu Astuti"],
    ["100443955", "2510272300020421", "Dian"],
    ["100444944", "2510272300020439", "Roro Dian"],
    ["100446501", "2510272300020454", "Adanti"],
    ["100446515", "2510272300020447", "Afra Fitrianita"],
    ["100448219", "2510272300020462", "Aika Hurairah"],
    ["100449237", "2510272300020488", "Widya Helani"],
    ["100449838", "2510272300020470", "Nurhidayati Surtiasih"],
    ["100454106", "2510272300020496", "Rika"],
    ["100455481", "2510272300020504", "Wahyu Gumilang"],
    ["100459258", "2510272300020645", "Sari Nurulita (Sari)"],
    ["100459342", "2510272300020512", "Theresia Lasma"],
    ["100461028", "2510272300020520", "Nur Hasinah"],
    ["100461838", "2510272300020538", "Christine Cink Winata"],
    ["100472694", "2510272300020546", "Haviani Sari"],
    ["100476890", "2510272300020553", "Miranti"],
    ["100486579", "2510272300020561", "Eka Mahayanawati"],
    ["100487053", "2510272300020579", "Jesika Jesika"],
    ["100492348", "2510272300020595", "Lisa"],
    ["100493817", "2510272300020603", "Rukmayani Sipahutar"],
    ["100496921", "2510272300020660", "Dyan Nanda Islami"],
    ["100499559", "2510272300020611", "Aziza Rohmah"],
    ["100501971", "2510272300020629", "Sriyoni Manik"],
    ["100506533", "2510272300020637", "Leona Lee"],
    ["100518974", "2510272300020652", "Sheila Nathania"],
    ["100522436", "2510272300020751", "Ainun Rosna Isnaini"],
    ["100535084", "2510272300020678", "Rizky Anggitarani"],
    ["100541118", "2510272300020686", "Sri Jayanti"],
    ["100542909", "2510272300020694", "Dian Utami Nainggolan"],
    ["100545181", "2510272300020702", "Faras Afifatul Khusna"],
    ["100546364", "2510272300020710", "Kirana Salsabela"],
    ["100546809", "2510272300020728", "Anti"],
    ["100550260", "2510272300020736", "Prety Sagita"],
    ["100556515", "2510272300020744", "Rismadayanti Waruwu"],
    ["100575941", "2510272300020769", "Gita Permana"],
    ["100581381", "2510272300020819", "Ressa Ristianti"],
    ["100582987", "2510272300020777", "Dwi Prihandayani"],
    ["100594807", "2510272300020785", "Fithriyah"],
    ["100600482", "2510272300020793", "Nina Soelfira"],
    ["100601127", "2510272300020827", "Imelda Susanti Hartono"],
    ["100601147", "2510272300020835", "Fadhilatul"],
    ["100606073", "2510272300020983", "Pujiiati / Ros"],
    ["100608900", "2510272300020843", "Nurhalimah"],
    ["100626119", "2510272300020850", "Eldia Anggidenia"],
    ["100628828", "2510272300020868", "Nining"],
    ["100636235", "2510272300020876", "Yohana Kadek Dwiastuti"],
    ["100640592", "2510272300020884", "Shifatur Rahmah"],
    ["100648336", "2510272300020892", "Mia"],
    ["100656971", "2510272300020900", "Dian Rahmawati"],
    ["100657034", "2510272300021064", "Tuty Nandayani"],
    ["100657232", "2510272300020918", "Celine Novia"],
    ["100664484", "2510272300020926", "Putri Marita Wijayanti"],
    ["100672260", "2510272300020934", "Nisa Khoiriyyatun"],
    ["100673373", "2510272300020942", "Stephanie Wijaya"],
    ["100688323", "2510272300020959", "Sari"],
    ["100695012", "2510272300020975", "Mina Audina"],
    ["100715131", "2510272300021015", "Noviyanti"],
    ["100716586", "2510272300020991", "Hasnita"],
    ["100719059", "2510272300021007", "Satrisna"],
    ["100729404", "2510272300021023", "Devi Amalia"],
    ["100736790", "2510272300021031", "Dede Indriani Asri"],
    ["100743181", "2510272300021049", "Puput Purwanti Ningsih"],
    ["100749066", "2510272300021056", "Cynthia Humaira"],
    ["100777328", "2510272300021072", "Lusy Nurbayati"],
    ["100787233", "2510272300021080", "Asri Khusufiah"],
    ["100792162", "2510272300021098", "Nadia Paramitha"],
    ["100814206", "2510272300021122", "Desi"],
    ["100823341", "2510272300021130", "Aulia Nurhidayah"],
    ["100352813", "2510272300021361", "Widyah Prilaili"],
    ["100744803", "2510272300021379", "Sati"],
    ["100796419", "2510272300021387", "Esti Mulyani"],
    ["100811384", "2510272300021411", "Bhinti Nur Khasanah"],
    ["100812921", "2510272300021445", "Yuli Safrida"],
    ["100844422", "2510272300021874", "Endah"],
    ["100848761", "2510272300021403", "Nurlia Miftakhul K"],
    ["100851314", "2510272300021429", "Siti Ubaeroh"],
    ["100856443", "2510272300021437", "Stevie Stephani"],
    ["100876852", "2510272300021452", "Ismi Elfia Farah"],
    ["100903339", "2510272300021460", "Kun Kanthi Ip"],
    ["100909868", "2510272300021486", "Della Nur Safitri"],
    ["100911977", "2510272300021478", "Mia Karmila"],
    ["100918010", "2510272300021494", "Sofia Ulpah"],
    ["100951601", "2510272300021510", "Lia Nuryani"],
    ["100952994", "2510272300021502", "Micca"],
    ["100966623", "2510272300021536", "Rahma Rosita"],
    ["100969008", "2510272300021528", "Wahyu Gusti Anisih (Asih)"],
    ["100978812", "2510272300021544", "Ristiana"],
    ["100979632", "2510272300021551", "Madina(Fatma Bustam)"],
    ["100980290", "2510272300021569", "Ayu Kumalasari"],
    ["100982817", "2510272300021577", "Eva Santi Siregar"],
    ["100994129", "2510272300021585", "Agatha Lindawati"],
    ["100995565", "2510272300021593", "Eka Damayanti"],
    ["101007499", "2510272300021601", "Resi"],
    ["101019579", "2510272300021700", "Martthauly Magdalena Sormin"],
    ["101029514", "2510272300021619", "Mira Ilham"],
    ["101062182", "2510272300021627", "Nisfi Azzahra"],
    ["101062897", "2510272300021635", "Defanny"],
    ["101070821", "2510272300021643", "Lisi"],
    ["101073822", "2510272300021650", "Rafika Lahilote"],
    ["101075343", "2510272300021676", "Dian Dwi Restiani"],
    ["101091227", "2510272300021684", "Indriani Mukti"],
    ["101093618", "2510272300021692", "Puty Naulita"],
    ["101138883", "2510272300021718", "Dwi Septianingsih"],
    ["101161132", "2510272300021726", "Rusmalaianti"],
    ["101196672", "2510272300021742", "Faizah Syahidah"],
    ["101196689", "2510272300021734", "Salsabila"],
    ["101196760", "2510272300021767", "Mita Sri Daniaty"],
    ["101196818", "2510272300021759", "Dianty"],
    ["101197028", "2510272300021940", "Hana"],
    ["101197095", "2510272300021817", "Eka Yulianti"],
    ["101197189", "2510272300021783", "Vera"],
    ["101197204", "2510272300021809", "Nandadita Larasati"],
    ["101197230", "2510272300022021", "Bibiana Widiastuti"],
    ["101197232", "2510272300021825", "Septian Maulita"],
    ["101197236", "2510272300021791", "Novitha Giovanny (Orange Kosmetik)"],
    ["101197250", "2510272300021775", "Asha"],
    ["101197262", "2510272300021858", "Eka Kristalia Kuryani"],
    ["101197296", "2510272300021882", "Kushartati"],
    ["101197466", "2510272300021833", "Ida Puspa Setyowati"],
    ["101197510", "2510272300021866", "Indah Hidayati"],
    ["101197543", "2510272300021841", "Eka Fitriatunnisa"],
    ["101197554", "2510272300022161", "Sari"],
    ["101197582", "2510272300022138", "Sri Mariyanti"],
    ["101197614", "2510272300021908", "Maya"],
    ["101197699", "2510272300021395", "Endah Purnamasari"],
    ["101197712", "2510272300021916", "Ciptaning Putri Kukuh Kusuma Pratiwi"],
    ["101197800", "2510272300021890", "Ade Yunia"],
    ["101197958", "2510272300021932", "Ogma Rista Dwi Putri"],
    ["101197978", "2510272300021924", "Ani Nurhayati"],
    ["101198094", "2510272300021957", "Warda"],
    ["101198225", "2510272300021965", "Dian Eka Januriwasti"],
    ["101198380", "2510272300022005", "Nurafni"],
    ["101198507", "2510272300021973", "Dewi Sukmaningsih"],
    ["101198513", "2510272300022070", "Munasah"],
    ["101198523", "2510272300021999", "Dita Andanswari"],
    ["101198845", "2510272300022187", "Hapsari (Tower A Kamar 14)"],
    ["101198867", "2510272300022013", "Feny Marlina"],
    ["101198954", "2510272300022039", "Elya Chairunnisa"],
    ["101199533", "2510272300022062", "Kiki Maulani"],
    ["101199630", "2510272300022047", "Erna Hartatik"],
    ["101199726", "2510272300022179", "Henny Wismar Sari"],
    ["101199733", "2510272300022054", "Anindita"],
    ["101199934", "2510272300022088", "Putri Endah"],
    ["101200450", "2510272300022096", "Nur Ayni Julaeha"],
    ["101203052", "2510272300022195", "Auliya Harani"],
    ["101208819", "2510272300022146", "Putri Swadia"],
    ["101217242", "2510272300022153", "Avia Desiana Ifran"],
    ["101345933", "2510272300022203", "Amy Mutiasari"],
    ["101479270", "2510272300022211", "Silvy Octavia"],
    ["101491162", "2510272300022278", "Venny Amilia Deslaweny"],
    ["101501166", "2510272300022229", "Sherrindra Avedta"],
    ["101511837", "2510272300022237", "Lila Syifa Fauzia"],
    ["101524801", "2510272300022245", "Annisa Azrin Nandita"],
    ["101572650", "2510272300022252", "Yuanita"],
    ["101584951", "2510272300022260", "Aliyah Anggi"],
    ["101604074", "2510272300022286", "Corri"],
    ["101609502", "2510272300022294", "Veronica Ngatio"],
    ["101621352", "2510272300022302", "Dayu Septiani"],
    ["101649862", "2510272300022310", "Rohayati"],
    ["101655298", "2510272300022328", "Refani Elysa (Epit)"],
    ["101658817", "2510272300022336", "Prillycia"],
    ["101660875", "2510272300022344", "Leny Ramadani"],
    ["101663707", "2510272300022351", "Khaerunnisa ( Icha)"],
    ["101669533", "2510272300022369", "Syarifah Puteri"],
    ["101680555", "2510272300022377", "Putri Handayani"],
    ["101695588", "2510272300022385", "Merry Anggria Larasati Kusmadewi"],
    ["101717323", "2510272300022393", "Diah Wahyu Muryastuti (Ayu)"],
    ["101760585", "2510272300022401", "Richa Stefani"],
    ["101778056", "2510272300022443", "Wa Ode Kartini (Tini)"],
    ["101780495", "2510272300022419", "Nurhayati"],
    ["101783349", "2510272300022427", "Mamik"],
    ["101789342", "2510272300022450", "Rizka Era Milenia"],
    ["101804018", "2510272300022468", "Ester"],
    ["101822087", "2510272300022476", "Dentia Wahyu Mukti"],
    ["101824845", "2510272300022518", "Maryam Hanifah"],
    ["101826094", "2510272300022534", "Isam Syamsiah"],
    ["101826743", "2510272300022484", "Hafsa Abida Nasruddin"],
    ["101844844", "2510272300022492", "Irene Gracia El'Shadekheia"],
    ["101888573", "2510272300022526", "Sophia Wijono"],
    ["101907072", "2510272300022542", "Junun"],
    ["101927413", "2510272300022559", "Subhany"],
    ["101928362", "2510272300022567", "Dian Mustika"],
    ["101929136", "2510272300022575", "Della Apridea"],
    ["102048613", "2510272300022583", "Kim Kim"],
    ["102058003", "2510272300022591", "Eriska Fristianti"],
    ["102086011", "2510272300022609", "Friskila Delfia Nande"],
    ["102089539", "2510272300022617", "Nur Lailu"],
    ["102094855", "2510272300022625", "Sri Wahyu Harjanti"],
    ["102102901", "2510272300022633", "Hikmawati"],
    ["102105991", "2510272300022674", "Risa Kartika"],
    ["102152187", "2510272300022641", "Marra"],
    ["102153789", "2510272300022658", "Meika"],
    ["102175802", "2510272300022666", "Jane Jeany Lionardo"],
    ["102211022", "2510272300022682", "Hala Rizqul Ilahiyah"],
    ["102218898", "2510272300022690", "Tri Yuliyanti"],
    ["102239612", "2510272300022708", "Nurima Sari"],
    ["102241122", "2510272300022716", "Nurul Fitria"],
    ["102265276", "2510272300022724", "Novita"],
    ["102284050", "2510272300022799", "Nanda Kartika"],
    ["102284209", "2510272300022732", "Fuji"],
    ["102288332", "2510272300022740", "Ratna Margaretha"],
    ["102289057", "2510272300022757", "Dani Kholifah"],
    ["102302761", "2510272300022765", "Ulfah Zulaikha"],
    ["102327706", "2510272300022773", "Riza Syafitri"],
    ["102344899", "2510272300022781", "Eka Dyah S"],
    ["102370736", "2510272300022807", "Intan Putri"],
    ["102418666", "2510272300022823", "Marshanti Emmadita"],
    ["102600540", "2510272300022856", "Felisia"],
    ["102622826", "2510272300022864", "Ulfa Novita"],
    ["102699138", "2510272300022872", "Rina Y U"],
    ["102767502", "2510272300022898", "Dwi Viarsani"],
    ["102774933", "2510272300022880", "Citra"],
    ["102822613", "2510272300022906", "Isneniwati"],
    ["102822858", "2510272300022989", "Dian Apriandini"],
    ["102843721", "2510272300022914", "Dede"],
    ["102850716", "2510272300022922", "Maya Siti Maysaroh"],
    ["102883014", "2510272300022930", "Puga Sharaz Wangi"],
    ["102961359", "2510272300022948", "Putri Dea Pertiwi"],
    ["102961717", "2510272300022955", "Ratna Putri W"],
    ["102974734", "2510272300022963", "Cahya"],
    ["102988455", "2510272300022971", "Sefty Wandira"],
    ["103084147", "2510272300022997", "Novita Lestari Tjandra"],
    ["103084623", "2510272300023003", "Maria Goretti Yuli Astuti (Tuti)"],
    ["103161054", "2510272300023011", "Fauhan Yuliana"],
    ["103212247", "2510272300023029", "Oktari Fiana"],
    ["103284506", "2510272300023037", "Esterina Bembo"],
    ["103301532", "2510272300023045", "Desi"],
    ["103311665", "2510272300023052", "Riski Putri"],
    ["103313453", "2510272300023078", "Karmila"],
    ["103335604", "2510272300023086", "Jahrotun Nisa"],
    ["103357678", "2510272300023094", "Joseline"],
    ["TWN001", "2510272300021981", "Aida"],
    ["101252273", "P2303100149160", "Nur Jannah"],
    ["101197690", "2510272300023391", "Zubaidah Juju"],
    ["103375760", "2510272300023474", "Sabila Fatimah"],
    ["103411196", "2510272300023482", "Fiki Mudri Katurohmah"],
    ["103432423", "2510272300023490", "Fina Bunga"],
    ["103433405", "2510272300023508", "Hanny Fauzia"],
    ["103435199", "2510272300023516", "Yessica Jong"],
    ["103435840", "2510272300023524", "Fiona Citra Dewi"],
    ["103438638", "2510272300023532", "Indah Gita Wardani"],
    ["103438656", "2510272300023540", "Diah Nurul Hidayati"],
    ["103453015", "2510272300023557", "Nisa"],
    ["103506775", "2510272300023565", "Maesa Kharisma O.I"],
    ["103534021", "2510272300023573", "Yuannita"],
    ["103542284", "2510272300023581", "Febie Devina"],
    ["103629394", "2510272300023599", "Deasy"],
    ["103654322", "2510272300023607", "Utari"],
    ["101692435", "2510272300023854", "Nurul Fuji Aripah"],
    ["103833894", "2510272300024225", "Annisa Rima"],
    ["103956617", "2510272300024258", "Clara Adrina"],
    ["103911813", "2510272300024241", "Hilary"],
    ["103814652", "2510272300024217", "Mei Diana Ambar Rini"],
    ["103843612", "2510272300024233", "Ulfa Nur Izza"],
    ["103742603", "2510272300024209", "Welsa Jaya"],
    ["103714110", "2510272300024183", "Wiris R.W"],
    ["104004244", "2510272300024431", "Putri Sm"],
    ["104052265", "2510272300024449", "Iyet"],
    ["104097854", "2510272300024456", "Rara Rantiya"],
    ["104365540", "2510272300024738", "Erny Catur"],
    ["104313503", "2510272300024696", "Farida Kusuma"],
    ["104373815", "2510272300024746", "Fitri Sugianti Summeh"],
    ["104333921", "2510272300024720", "Fony"],
    ["104275974", "2510272300024670", "Hilia"],
    ["104160353", "2510272300024662", "Nike Yolanda"],
    ["104293712", "2510272300024688", "Pingkan"],
    ["104320055", "2510272300024712", "Vista Ayuningtyas"],
    ["104396716", "2510272300024753", "Widia"],
    ["104149744", "2510272300024654", "Winda Oktaviani"],
    ["104313454", "2510272300024704", "Yuli Hendrika Sugiharti"],
    ["104449100", "2510272300024993", "Francisca Johanes"],
    ["104516327", "2510272300026329", "Anatasyafira Desiana"],
    ["104510620", "2510272300026311", "Ines"],
    ["104467673", "2510272300026295", "Kusriniarti Dwi Lestariningsih"],
    ["104497322", "2510272300026303", "Meriza"],
    ["105175396", "2510272300026816", "Agnes Pujiaridiati"],
    ["105148326", "2510272300026808", "Amanda Chibakhitah"],
    ["105287111", "2510272300026857", "Anna Theresia"],
    ["104736365", "2510272300026600", "Ayu Wulandari"],
    ["104763416", "2510272300026659", "Ayupradita"],
    ["104718468", "2510272300026584", "Damay"],
    ["104838719", "2510272300026691", "Dani Irma Saragih"],
    ["105046449", "2510272300026758", "Dara"],
    ["104580007", "2510272300026568", "Devina Carolina"],
    ["105247483", "2510272300026832", "Dilla"],
    ["105025314", "2510272300026741", "Dzikri Udkhia"],
    ["104917982", "2510272300026717", "Imaa"],
    ["104754508", "2510272300026634", "Lia Natalia Leman"],
    ["105256699", "2510272300026840", "Made Dinda"],
    ["105058664", "2510272300026766", "Merry"],
    ["105131763", "2510272300026782", "Mia Fitriyanti"],
    ["104645148", "2510272300026576", "Nawang Ayu Jatiningtyas"],
    ["105017363", "2510272300026733", "Nurfazriah"],
    ["104734411", "2510272300026592", "Nurul Oktavia Agri"],
    ["104848191", "2510272300026709", "Oca"],
    ["104815510", "2510272300026675", "Orlen Veronica Solichin"],
    ["105144242", "2510272300026790", "Putri Nurvitasari"],
    ["104955900", "2510272300026725", "Riska Purnamasari"],
    ["104819812", "2510272300026683", "Rosy Nur Intan"],
    ["104756166", "2510272300026642", "Siti Hardiyanti Nurhasanah"],
    ["105102809", "2510272300026774", "Takayani"],
    ["104738956", "2510272300026618", "Tarwinah"],
    ["104528846", "2510272300026550", "Trisna Dewi"],
    ["104751543", "2510272300026626", "Yean Vp"],
    ["104783233", "2510272300026667", "Yulin Zurina"],
    ["105220640", "2510272300026824", "Zulfitriani"],
    ["105249252", "2510272300026964", "Septia Sari"],
    ["105411402", "2510272300026972", "Tiur Frida"],
    ["105643620", "2510272300027087", "Anandha Rahmadini"],
    ["105652074", "2510272300027095", "Anggra Damayanti"],
    ["105482171", "2510272300027111", "Muna Iman Riani"],
    ["105633518", "2510272300027129", "Resa"],
    ["105685027", "2510272300027103", "Sari Putri Firmansyah"],
    ["105721944", "2510272300027699", "Selly Ristya Ningsih"],
    ["105730482", "2510272300027707", "Enita Nopriani"],
    ["105785139", "2510272300027715", "Priscilia Primadona"],
    ["105793947", "2510272300027723", "Ria Rahayu"],
    ["105810063", "2510272300027731", "Niken Cahya Agnesyana"],
    ["105814949", "2510272300027749", "Nishrina Irfani"],
    ["105824706", "2510272300027756", "Masdhiana Sukmawarni"],
    ["105931581", "2510272300027764", "Tiya Mardiyati D"],
    ["105943520", "2510272300027772", "Irma Mutiarini"],
    ["105999775", "2510272300027871", "Nur Fairus Ramadhanti"],
    ["106008982", "2510272300027780", "Hana Almira"],
    ["106023167", "2510272300027798", "Nila Fa'Idatul Fitri Mubarokah"],
    ["106034490", "2510272300027806", "Nani Harpiyanti Selastrilia"],
    ["106036723", "2510272300027814", "Arum"],
    ["106056208", "2510272300027822", "Ikya Utami"],
    ["106061273", "2510272300027830", "Hanna"],
    ["106062088", "2510272300027848", "Rini"],
    ["106068410", "2510272300027855", "Rosana Puspasari"],
    ["106068495", "2510272300027863", "Athifah Nur Husna"],
    ["106089729", "2510272300027889", "Anna"],
    ["106098884", "2510272300027962", "Diningrum Citraningsih"],
    ["106099805", "2510272300027897", "Nanda Amelia"],
    ["106102572", "2510272300027905", "Lin"],
    ["106106397", "2510272300027913", "Isni Alfiatur Rohmah"],
    ["106120102", "2510272300027921", "Camelia Chasanah"],
    ["106131565", "2510272300027939", "Linda Aisyah"],
    ["106150636", "2510272300027947", "Estu Suryowati"],
    ["106151192", "2510272300027954", "Mega Ayulia"],
    ["106169683", "2510272300027970", "Ai Nuryanti"],
    ["106177465", "2510272300027988", "Rekha Novia"],
    ["106180229", "2510272300027996", "Husna Farida"],
    ["106182654", "2510272300028101", "Rani Kuraisyin"],
    ["106193321", "2510272300028002", "Indah Finita Lian Christiani"],
    ["106193541", "2510272300028010", "Ashri Shabrina Afrah"],
    ["106205907", "2510272300028028", "Yuliani Tamara"],
    ["106209861", "2510272300028036", "Intan"],
    ["106219431", "2510272300028044", "Dewi Fatmawati"],
    ["106213387", "2510272300028671", "Yanti Sapitri"],
    ["106266308", "2510272300028515", "Devina Raisya Zahrani"],
    ["106272005", "2510272300028523", "Amica Cahyanti"],
    ["106293349", "2510272300028531", "Arin Maya Rosita"],
    ["106295458", "2510272300028549", "Ayuna"],
    ["106295626", "2510272300028556", "Ayu Styefani"],
    ["106323844", "2510272300028564", "Karin"],
    ["106337982", "2510272300028572", "Silmi Agustin"],
    ["106352485", "2510272300028580", "Nepi Haryuni Jayanti Utamie"],
    ["106375497", "2510272300028598", "Marhatun Wahidah"],
    ["106377186", "2510272300028606", "Pretty W"],
    ["106381796", "2510272300028614", "Riana Tifani"],
    ["106386250", "2510272300028622", "Resta Purba"],
    ["106389120", "2510272300028630", "Wardah"],
    ["106389711", "2510272300028648", "Fonna Maulidyah"],
    ["106391317", "2510272300028655", "Ratih Pratiwi"],
    ["106396637", "2510272300028663", "Mamdukhah Maharani"],
    ["106438454", "2510272300028853", "Yukiko"],
    ["106447815", "2510272300028861", "Siti Yulia Hillu Mahmudah"],
    ["106457055", "2510272300028879", "Muhajirah Dahlan"],
    ["106462125", "2510272300028887", "Afifah Mahardika"],
    ["106463102", "2510272300028895", "Anita/Zibby"],
    ["106495957", "2510272300028903", "Ainun Drakel"],
    ["106511616", "2510272300028911", "Annisa Ika Rahmawati"],
    ["106540870", "2510272300030164", "Dona"],
    ["106541640", "2510272300029877", "Yunis Dianawati"],
    ["106554383", "2510272300029885", "Fadhiilatun Nisaa"],
    ["106563614", "2510272300029893", "Chandra Murnihandayani"],
    ["106575951", "2510272300030156", "Wineu Rosma"],
    ["106595179", "2510272300029901", "Cory Saragih"],
    ["106605940", "2510272300029919", "Alfi Rosyida"],
    ["106606553", "2510272300029927", "Ikrim Mawaddah"],
    ["106611157", "2510272300029935", "Yuhaeti"],
    ["106623241", "2510272300029943", "Devy Lestari"],
    ["106633750", "2510272300029950", "Raga Dista Syahbaniar Putri W"],
    ["106642246", "2510272300029968", "Dyah Kartika Sari"],
    ["106647741", "2510272300029976", "Cahyaningrum"],
    ["106658128", "2510272300029984", "Ratu"],
    ["106660567", "2510272300029992", "Dina Khoirotun Nisa"],
    ["106692243", "2510272300030008", "Uko Rahayu Ningsih"],
    ["106694809", "2510272300030016", "Ismi"],
    ["106697058", "2510272300030024", "Mita"],
    ["106701800", "2510272300030032", "Lena Sosianti"],
    ["106706793", "2510272300030040", "Nadia Husna"],
    ["106708489", "2510272300030057", "Cahya Kartika"],
    ["106713807", "2510272300030065", "Khusnun Nisa Zahro"],
    ["106721418", "2510272300030081", "Rizka Miladyah Fauzan"],
    ["106735830", "2510272300030073", "Aisyah Nur Sayyidatun Nisa"],
    ["106738711", "2510272300030099", "Selvia"],
    ["106743573", "2510272300030115", "Chik"],
    ["106743617", "2510272300030107", "Fitri Nurjanah"],
    ["106747070", "2510272300030131", "Dian Lestari"],
    ["106814801", "2510272300030149", "Sielvyana"],
    ["107051261", "2510272300030628", "Siti Sunya Kumala"],
    ["107062175", "2510272300030636", "Sari"],
    ["107161583", "2510272300030644", "Amalia"],
    ["107182947", "2510272300031113", "Rachel"],
    ["107286415", "2510272300031253", "Herlanggita"],
    ["107324835", "2510272300031261", "Ika Muplihah"],
    ["107386497", "2510272300031279", "Nur Fajriyah"],
    ["107501950", "2510272300031469", "Dina Nur Hashifah"],
    ["107947609", "080400003411123", "Tina"],
    ["107935371", "080400002845923", "Dian Dwi Jayanti"],
    ["107856678", "080400002470123", "Shabrina Dyah Mayangsari"],
    ["107935371", "085974747092	Dian Dwi Jayanti"],
    ["107947609", "081514104941	Tina"],
    ["109400107", "083833448741	Eli Suryani"],
    ["109566826", "081286524928	Ghina"],
    ["109583327", "085273032600	Nungky Anistia Sari"],
    ["109585809", "0450806578	Ester"],
    ["109664937", "087780226722	Stella"],
    ["109680317", "082231004499	Ghiana"],
    ["109726129", "089674750227	Maulidina Imas Ingkadijaya"],
    ["109726773", "07749124507	Tracy Saw"],
    ["109734530", "085722514166	Ratu Irfi"],
    ["109834918", "082141918989	Mening"],
    ["109896607", "081286677661	Lokita"],
    ["105249252", "087871998108	Septia Sari"],
    ["105482171", "087727730603	Muna Iman Riani"],
    ["105633518", "089634609115	Resa"],
    ["106213387", "083107066025	Yanti Sapitri"],
    ["106575951", "0811311502	Wineu Rosma"],
    ["107522849", "085283785274	Intan Sihombing"],
    ["109834918", "2510272300035460	Mening"],
    ["109896607", "2510272300035478	Lokita"],
    ["109992830", "2510272300035684	Anggraini Budi Lestari"],
    ["110015753", "2510272300035692	Maria Marsheila Immaculata"],


    ["110015753", "2510272300035692	Maria Marsheila Immaculata"],
    ["110126248","083120004138423","Alifia"],
    ["110126248","2510272300036310","Alifia"],
    ["110417545","2510272300036344","Evi Nilasari"],
    ["110267415","2510272300036328","Fatma"],
    ["110291463","2510272300036336","Nur Islamia"],
    ["110640560","2510272300037037","Jeri Ananda/ Yeti Nasa"],
    ["110681421","2510272300037045","Desy Corina"],
    ["110821940","2510272300037292","Veronika"],
    ["110867282","2510272300037300","Voni"],
    ["110823294","2510272300037318","Julenda Nikijuluw"],
    ["111036563","2510272300037706","Dewanti Pratiwi"],
    ["111075130","2510272300037714","Rachmatika Putri Rizky"],
    ["111093037","2510272300037722","Istiqomah"],
    ["111102770","2510272300037730","Dewi Murni"],
    ["111114507","2510272300037748","Yohana Christina"],
    ["111194979","2510272300037755","Iva"],
    ["111216393","2510272300037763","Enggar"],
    ["111355738","2510272300038142","Nurjamian Mira (Ani)"],
    ["111410941","2510272300038159","Ina"],
    ["111959739","2510272300038670","Desy Ika"],
    ["111624255","2510272300038647","Fitri Arianawati"],
    ["111978406","2510272300038688","Iie Noor Ilahi"],
    ["111759421","2510272300038654","Noviyanti Siti Maryam"],
    ["111947609","2510272300038662","Patricia"],
    ["111942888","2510272300038894","Adenya Vidy Hendharning"],
    ["112039099","2510272300038852","Arum (Primadiani Nurhanidar)"],
    ["112055520","2510272300038878","Bella Fawzi"],
    ["112038554","2510272300038886","Novita"],
    ["112048467","2510272300038860","Olivia O"],
    ["112145009","2510272300038993","Yana"],
    ["112176868","2510272300039009","Natazza Jehan Ardhiyani"],
    ["112199863","2510272300039017","Suci Zalia"],
    ["112194660","2510272300039025","Arsilla Mustikaning Hapsari"],
    ["112308503","2510272300039637","Mitha"],
    ["112412614","2510272300039645","Restia"],
    ["112508871","2510272300039652","Mita Kosasih"],
    ["150060747","2510272300039660","Nurrahma"],
    ["150271352","2510272300040023","Agung Pradita"],
    ["150340005","2510272300040031","Nandang P."],
    ["150407830","2510272300040486","Shelly Lavenia Sambodo"],
    ["150557115","2510272300040502","Novi Hasan"],
    ["150754578","2510272300040874","RATIH EKA SETYORINI"],
    ["150757701","2510272300040882","Setia Andrianita (Tia)"],
    ["150881429","2510272300040890","Bu Tutik (Dita Lab)"],
    ["150631186","2510272300040932","Desak Hariatik"],
    ["151187935","2510272300041484","Maria Pangestu"],
    ["151229013","2510272300041492","Jessita Ryosuke"],
    ["151315321","2510272300041658","Karina Permatasari"],
    ["151565331","2510272300042359","Lusy Wulandari"],
    ["151578775","2510272300042367","Adriella Sakinah"],
    ["151678261","2510272300042375","Kanti"],
    ["151851314","2510272300042508","Rizka"],
    ["152001905","2510272300042888","Aurelia Susanti"],
    ["152101658","2510272300043092","Vita"],
    ["152244938","2510272300043274","Hesti"],
    ["152398683","2510272300043282","Laila Rahma"],
    ["152441664","2510272300043290","Hetty Maha"],
    ["152643167","2510272300045097","Almira Luthfi Mutiara"],
    ["152777862","2510272300045105","Yennie"],
    ["152873702","2510272300045113","Prameswari Titanidha"],
    ["154543883","2510272300068081","Willi Yuliani"],
    ["154651773","2510272300067901","Avitia Nurmatari"],
    ["154703529","2510272300068099","Dwi Ni'Maturrohmah (Ima)"],
    ["154750544","2510272300070392","Jayanti Fatimah"],
    ["154796286","2510272300070400","Yuntarti Istiqomalia"],
    ["154897392","2510272300070483","Masriyah"],
    ["154921447","2510272300070681","Anisa Swediana Salamah"],
    ["155523725","2510272300071895","Angelica"],
    ["155527284","2510272300071903","Salsabila"],
    ["155557362","2510272300071911","Andriani Fitri Nasution"],
    ["156175744","2510272300073743","Dewi"],
    ["156094656","2510272300073958","Dianti Nurmalasari"],
    ["155884618","2510272300073735","Fransisca Romana Dk"],
    ["156065050","2510272300073941","Ni Made Mega Melinda Desiarinie"],
    ["156366433","2510272300074097","Zuan Ashifana"],

  ]
}

export default App
